import { cn } from "@nephroflow/design-system/styling/utils";

import { type IconProps, RadioButtonCheckedIcon, RadioButtonUncheckedIcon } from "./icon";

function RadioIcon({
  checked = false,
  disabled = false,
  className,
  ...props
}: { checked?: boolean; disabled?: boolean } & IconProps) {
  const Icon = checked ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon;

  return (
    <Icon
      {...props}
      className={cn(
        "rounded-full transition-colors",
        {
          "text-blue-100 hover:text-blue-110 [&:has(+input:hover)]:text-blue-110": checked && !disabled,
          "text-blue-50 hover:text-blue-60 [&:has(+input:hover)]:text-blue-60": !checked && !disabled,
          "cursor-not-allowed text-gray-20": disabled,
        },
        className,
      )}
    />
  );
}

export { RadioIcon };
