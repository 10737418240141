import { cn, cva } from "@nephroflow/design-system/styling/utils";
import * as Popover from "@radix-ui/react-popover";
import { Store } from "@tanstack/react-store";
import isEqual from "lodash.isequal";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { useSyncExternalStoreWithSelector } from "use-sync-external-store/with-selector.js";

import { defineMessages, FormattedMessage, isMessageDescriptor, MessageDescriptor, useIntl } from "~/intl";

import { Button as TertiaryButton } from "~/shared/components/button";
import { CheckboxIcon } from "~/shared/components/checkbox-icon";
import { Chip } from "~/shared/components/chip";
import { DropdownMenu } from "~/shared/components/dropdown-menu";
import { ArrowDownIcon, CloseIcon, SearchIcon } from "~/shared/components/icon";
import { RadioIcon } from "~/shared/components/radio-icon";
import { matchText } from "~/shared/utils";

import { invariant } from "~/utils/invariant";

interface DataAttrs {
  [dataAttr: `data-${string}`]: string | undefined;
}

type AnyOption = LeafOption | GroupOption;

interface LeafOption {
  label: string;
  value: string;
}

interface GroupOption {
  label: string;
  options: LeafOption[];
}

type Value<Multi extends boolean> = Multi extends false ? string | null : string[] | null;

type OnChange<Multi extends boolean, Clearable extends boolean> = Multi extends false
  ? (value: Clearable extends false ? string : string | null) => void
  : (value: string[]) => void;

type Components = {
  SingleValue: React.ComponentType;
  MultiValue: React.ComponentType;
  Option: React.ComponentType<React.ComponentProps<typeof Option>>;
  GroupOption: React.ComponentType<React.ComponentProps<typeof GroupOption>>;
  LeafOption: React.ComponentType<React.ComponentProps<typeof LeafOption>>;
};

export interface SelectInputImparativeHandle {
  closeMenu: () => void;
}

type Props<Multi extends boolean, Clearable extends boolean> = {
  id?: string;
  name?: string;
  value?: Value<Multi>;
  defaultValue?: Value<Multi>;
  onChange?: OnChange<Multi, Clearable>;
  options: readonly AnyOption[];
  multiple?: Multi;
  clearable?: Clearable;
  children: React.ReactNode;
  placeholder?: string | MessageDescriptor;
  isOpen?: boolean;
} & Partial<Components>;

const SelectInputInner = <Multi extends boolean = false, Clearable extends boolean = true>(
  {
    id,
    name,
    value,
    defaultValue,
    onChange,
    options,
    multiple = false as Multi,
    clearable = true as Clearable,
    children,
    SingleValue,
    MultiValue,
    Option,
    GroupOption,
    LeafOption,
    placeholder = "Select...",
  }: Props<Multi, Clearable>,
  ref: React.Ref<SelectInputImparativeHandle | null>,
) => {
  const uid = React.useId();

  const components = React.useMemo(
    () => ({ SingleValue, MultiValue, Option, GroupOption, LeafOption }),
    [SingleValue, MultiValue, Option, GroupOption, LeafOption],
  );

  const isControlled = value !== undefined && defaultValue === undefined;

  const selectRef = React.useRef<HTMLSelectElement | null>(null);
  const renderedOnce = React.useRef(false);

  const [store] = React.useState(() => {
    const selection = valueToSelection(isControlled ? value || null : defaultValue || null);
    return new SelectStore({
      uid,
      selection,
      options,
      multiple,
      clearable,
      placeholder,
      components,
    });
  });

  // Sync native select when state changed
  store.onChange = (selection) => {
    const select = selectRef.current;
    if (select) {
      syncNativeSelect(select, selection);
      select.dispatchEvent(new Event("change", { bubbles: true }));
    }
  };

  React.useLayoutEffect(() => {
    // Sync native select on initial render
    if (!renderedOnce.current) {
      const select = selectRef.current;
      if (select) syncNativeSelect(select, store.state.selection);
      renderedOnce.current = true;
    }

    // Sync state with props
    store.setState((prev) => {
      const next = { ...prev };
      next.options = options;
      next.multiple = multiple;
      next.clearable = clearable;
      next.placeholder = placeholder;
      next.components = components;

      // Update selection if controlled
      if (isControlled) {
        const nextSelection = valueToSelection(value);
        if (!isEqual(prev.selection, nextSelection)) {
          next.selection = nextSelection;
        }
      }

      // Clear selection if changing from multiple to single
      if (prev.multiple && !next.multiple) {
        next.selection = [];
        store.onChange([]);
      }

      // Keep previous options if they didn't really change
      if (prev.options !== next.components) {
        if (isEqual(prev.options, next.options)) {
          next.options = prev.options;
        }
      }

      return next;
    });
  });

  const closeMenu = () => {
    store.setIsMenuOpen(false);
  };

  React.useImperativeHandle(ref, () => {
    return {
      closeMenu,
    };
  });

  const isMenuOpen = useSelector(store, (state) => state.isMenuOpen);

  return (
    <>
      <StoreContext.Provider value={store}>
        <Popover.Root open={isMenuOpen} onOpenChange={(isMenuOpen) => store.setIsMenuOpen(isMenuOpen)}>
          {children}
        </Popover.Root>
      </StoreContext.Provider>

      <select
        ref={selectRef}
        id={id}
        name={name}
        multiple={multiple}
        tabIndex={-1}
        // @ts-expect-error Inert not supported
        inert=""
        aria-hidden
        className="hidden"
        onChange={(event) => {
          const select = event.currentTarget;
          const options = select.querySelectorAll<HTMLOptionElement>("option");
          const selection = [...options].map((option) => option.value);

          if (isControlled) {
            const value: any = multiple ? selection : selection[0] || null;
            onChange?.(value);
          }
        }}
      />
    </>
  );
};

const SelectInput = React.forwardRef(SelectInputInner) as <
  Multi extends boolean = false,
  Clearable extends boolean = true,
>(
  props: Props<Multi, Clearable> & {
    ref?: React.Ref<SelectInputImparativeHandle | null>;
  },
) => ReturnType<typeof SelectInputInner>;

function Trigger({ children, ...props }: React.ComponentProps<typeof Popover.Trigger>) {
  return (
    <Popover.Trigger asChild {...props}>
      {children}
    </Popover.Trigger>
  );
}

function useHighlightOption() {
  const store = useStore();

  const highlightOption = React.useCallback(
    (index: number) => {
      const { search, options } = store.state;
      const matchedOptions = leafOptions(matchingOptions(options, search));
      const option =
        index === -1
          ? matchedOptions.at(-1)
          : index === matchedOptions.length
            ? matchedOptions.at(0)
            : matchedOptions.at(index);

      if (option) {
        store.setHighlightedOption(option.value, "keyboard");
        scrollToOption(store.state.uid, option.value);
      }
    },
    [store],
  );

  return { highlightOption };
}

function Menu(props: React.ComponentProps<typeof DropdownMenu>) {
  const store = useStore();
  const multiple = useSelector(store, (s) => s.multiple);
  const { highlightOption } = useHighlightOption();

  const canSearch = useSelector(store, (s) => s.canSearch);

  return (
    <DropdownMenu
      role="listbox"
      aria-multiselectable={multiple ? true : undefined}
      className={cn("max-h-[45rem]", { "max-h-[51rem]": canSearch }, props.className)}
      onKeyDown={(event) => {
        const { search, options, highlightedValue } = store.state;
        const matchedOptions = leafOptions(matchingOptions(options, search));
        const highlightedIndex = matchedOptions.findIndex((option) => option.value === highlightedValue);

        switch (event.key) {
          case "ArrowUp": {
            highlightOption(!highlightedValue ? -1 : highlightedIndex - 1);
            break;
          }
          case "ArrowDown": {
            highlightOption(!highlightedValue ? 0 : highlightedIndex + 1);
            break;
          }
          case "Enter": {
            if (highlightedValue) store.toggleOption(highlightedValue, "keyboard");
            break;
          }
        }
      }}
      {...props}
    />
  );
}

function Search({ placeholder }: { placeholder?: MessageDescriptor | string }) {
  const { formatMessage } = useIntl();
  const store = useStore();
  const search = useSelector(store, (s) => s.search);
  const { highlightOption } = useHighlightOption();

  React.useLayoutEffect(() => {
    store.setState((state) => ({ ...state, canSearch: true }));
    return () => {
      store.setState((state) => ({ ...state, canSearch: false }));
    };
  }, [store]);

  return (
    <div className="z-10 grid bg-white [&>*]:col-start-1 [&>*]:row-start-1">
      <div className="pointer-events-none z-10 px-4 py-3">
        <SearchIcon className="text-gray-30" />
      </div>
      <input
        data-searchid={store.state.uid}
        className="rounded-t-[7px] border-b border-gray-10 bg-blue-00 py-3 pl-12 pr-4 outline-none placeholder:text-gray-40"
        placeholder={typeof placeholder === "string" ? placeholder : formatMessage(placeholder || t.search)}
        type="text"
        value={search || ""}
        onChange={(event) => {
          let value: string | null = event.target.value;
          if (value === "") value = null;
          ReactDOM.flushSync(() => store.setSearch(value));

          const optionId = `${store.state.uid}-${store.state.highlightedValue}`;
          const highlightedOption = document.querySelector(`[data-optionid="${optionId}"]`);
          if (!highlightedOption) highlightOption(0);
        }}
      />
    </div>
  );
}

function useOptionsData() {
  const store = useStore();
  const isMenuOpen = useSelector(store, (s) => s.isMenuOpen);
  const options = useSelector(store, (s) => s.options);
  const search = useSelector(store, (s) => s.search);
  const matchedOptions = matchingOptions(options, search);

  const props = {
    className: "flex min-w-0 flex-col overflow-y-auto",
  } as const satisfies React.HTMLAttributes<HTMLDivElement> & DataAttrs;

  const scrolledRef = React.useRef(false);
  React.useLayoutEffect(() => {
    if (!scrolledRef.current && isMenuOpen) {
      requestAnimationFrame(() => {
        document.querySelector("[data-selected]")?.scrollIntoView({ block: "nearest" });
      });
      scrolledRef.current = true;
    }
  });

  return { props, matchedOptions };
}

function Options() {
  const { props, matchedOptions } = useOptionsData();
  const Option = useResolveComponent("Option");

  return matchedOptions.length ? (
    <div {...props}>
      {matchedOptions.map((option, index) => (
        <Option key={index} option={option} />
      ))}
    </div>
  ) : (
    <div className="p-3">
      <FormattedMessage {...t.noOptions} />
    </div>
  );
}

function Actions({ onClear }: { onClear: () => void }) {
  return (
    <div className="flex justify-between border-t border-gray-10 bg-gray-00 p-2">
      <TertiaryButton type="reset" impact="neutral" importance="tertiary" onClick={onClear}>
        <FormattedMessage {...t.clear} />
      </TertiaryButton>
    </div>
  );
}

function Option({ option }: { option: AnyOption }) {
  const GroupOption = useResolveComponent("GroupOption");
  const LeafOption = useResolveComponent("LeafOption");

  return "options" in option ? <GroupOption option={option} /> : <LeafOption option={option} />;
}

function useGroupOptionData({ option }: { option: GroupOption }) {
  const store = useStore();
  const groupId = React.useId();
  const labelId = `${store.state.uid}-${groupId}`;

  const props = {
    "className": cn("option-group", "flex min-w-0 flex-col"),
    "role": "group",
    "aria-labelledby": labelId,
  } as const satisfies React.HTMLAttributes<HTMLDivElement> & DataAttrs;

  const labelProps = {
    id: labelId,
    className: cn(
      "relative border-b border-gray-20 bg-gray-10 px-4 text-sm font-medium uppercase text-secondary",
      // Line over the bottom line of the leaf option above
      "after:absolute after:left-0 after:right-0 after:h-[1px] after:bg-gray-20 after:content-['']",
    ),
    children: option.label,
  } as const satisfies React.HTMLAttributes<HTMLDivElement> & DataAttrs;

  return { groupId, labelId, props, labelProps };
}

function GroupOption({ option }: { option: GroupOption }) {
  const { props, labelProps } = useGroupOptionData({ option });
  const LeafOption = useResolveComponent("LeafOption");

  return (
    <div {...props}>
      <div {...labelProps} />
      {option.options.map((option, index) => (
        <LeafOption key={index} option={option} />
      ))}
    </div>
  );
}

function useLeafOptionData({ option }: { option: LeafOption }) {
  const store = useStore();
  const options = useSelector(store, (s) => s.options);
  const multiple = useSelector(store, (s) => s.multiple);
  const canSearch = useSelector(store, (s) => s.canSearch);
  const search = useSelector(store, (s) => s.search);
  const selection = useSelector(store, (s) => s.selection);
  const highlightVisible = useSelector(store, (s) => s.highlightVisible);
  const highlightedValue = useSelector(store, (s) => s.highlightedValue);

  const isHighlighted = highlightVisible && option.value === highlightedValue;
  const isSelected = selection.includes(option.value);
  const matchedOptions = leafOptions(matchingOptions(options, search));
  const isFirst = option.value === matchedOptions.at(0)?.value;
  const isLast = option.value === matchedOptions.at(-1)?.value;

  const Icon = multiple ? CheckboxIcon : RadioIcon;
  const icon = <Icon className={cn("shrink-0", isSelected ? "text-blue-100" : "text-blue-50")} checked={isSelected} />;

  const optionId = `${store.state.uid}-${option.value}`;
  const labelId = `${store.state.uid}-${option.value}-label`;

  const props = {
    "tabIndex": -1,
    "data-optionid": optionId,
    "data-selected": isSelected ? "" : undefined,
    "data-highlighted": isHighlighted ? "" : undefined,
    "role": "option",
    "aria-selected": isHighlighted,
    "aria-checked": isSelected,
    "aria-labelledby": labelId,
    "onClick": () => store.toggleOption(option.value, "mouse"),
    "onKeyDown": (event) => {
      if (event.code === "Enter" || event.code === "Space") {
        event.preventDefault();
        store.toggleOption(option.value, "keyboard");
      }
    },
    "className": cn(
      "relative flex items-center gap-2 px-4 py-3 text-left transition-colors",
      "hover:cursor-default hover:bg-gray-10",
      // Line below the option
      "after:absolute after:bottom-0 after:left-[1.75rem] after:right-0 after:h-[1px] after:bg-gray-10 after:content-['']",
      // Hide line when an option group is below
      "[&:has(+.option-group)]:after:hidden",
      // Hide line when a highlighted option is below
      "[&:has(+[data-highlighted])]:after:hidden",
      {
        "ring-1 ring-inset ring-black after:hidden": isHighlighted,
        "rounded-t-[7px]": isFirst && !canSearch,
        "rounded-b-[7px] after:hidden": isLast,
      },
    ),
  } as const satisfies React.HTMLAttributes<HTMLDivElement> & DataAttrs;

  const labelProps = {
    id: labelId,
  } as const satisfies React.HTMLAttributes<HTMLDivElement> & DataAttrs;

  return {
    isHighlighted,
    isSelected,
    isLast,
    icon,
    optionId,
    labelId,
    props,
    labelProps,
  };
}

function LeafOption({ option }: { option: LeafOption }) {
  const { icon, props, labelProps } = useLeafOptionData({ option });

  return (
    <div {...props}>
      {icon}
      <div {...labelProps}>{option.label}</div>
    </div>
  );
}

const buttonVariants = cva(
  [
    "flex min-h-12 min-w-24 items-center justify-between gap-4 rounded px-4 py-2 transition-colors",
    "focus-visible:border-blue focus-visible:outline-none",
    "border",
  ],
  {
    variants: {
      type: {
        regular: "border-blue-50 bg-blue-00",
        inline: "",
      },
      menuState: {
        open: "border-blue-50 bg-blue-00",
        closed: [
          "focus-visible:bg-blue-00",
          "[&:not([disabled]):hover:not(:focus-visible)]:border-blue-50 [&:not([disabled]):hover:not(:focus-visible)]:bg-blue-10",
        ],
      },
      disabled: {
        true: "border-gray-30 bg-gray-00 text-gray-30 placeholder:text-gray-30",
      },
    },
    defaultVariants: {
      type: "regular",
      menuState: "closed",
    },
  },
);

const Button = React.forwardRef<
  HTMLButtonElement,
  Omit<React.HTMLAttributes<HTMLButtonElement>, "children"> & {
    inline?: { backdrop: string; border: string };
    disabled?: boolean;
  }
>(({ inline, className, disabled = false, ...props }, ref) => {
  const { formatMessage } = useIntl();
  const store = useStore();
  const isMenuOpen = useSelector(store, (s) => s.isMenuOpen);
  const selection = useSelector(store, (s) => s.selection);
  const clearable = useSelector(store, (s) => s.clearable);
  const multiple = useSelector(store, (s) => s.multiple);

  const MultiValue = useResolveComponent("MultiValue");
  const SingleValue = useResolveComponent("SingleValue");

  const hasSelection = selection.length;
  const canClear = hasSelection && clearable && hasSelection > 1;

  const onClear = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
    store.setSelection([]);
  };

  return (
    <button
      ref={ref}
      className={cn(
        buttonVariants({
          type: !inline ? "regular" : "inline",
          menuState: isMenuOpen ? "open" : "closed",
          disabled,
        }),
        inline && !isMenuOpen ? `${inline.border} ${inline.backdrop}` : "",
        className,
      )}
      {...props}
      disabled={disabled}
    >
      {multiple ? <MultiValue /> : <SingleValue />}
      <div className="flex">
        {canClear ? (
          <div
            role="button"
            tabIndex={-1}
            className="flex h-8 w-6 items-center justify-center"
            aria-label={formatMessage(t.clear)}
            onClick={(event) => onClear(event)}
            onKeyDown={(event) => {
              if (event.code === "Enter" || event.code === "Space") {
                event.preventDefault();
                onClear(event);
              }
            }}
          >
            <CloseIcon className="h-4 w-4" />
          </div>
        ) : null}
        <div className="flex h-8 w-6 items-center justify-center">
          <ArrowDownIcon />
        </div>
      </div>
    </button>
  );
});
Button.displayName = "SelectInputButton";

function useSingleValueData() {
  const store = useStore();
  const selection = useSelector(store, (s) => s.selection);
  const options = useSelector(store, (s) => s.options);
  const placeholder = useSelector(store, (s) => s.placeholder);

  const props = {
    className: "flex items-center h-8",
  } as const satisfies React.HTMLAttributes<HTMLDivElement> & DataAttrs;

  const option = leafOptions(options).find((option) => selection.includes(option.value));

  return { props, option, placeholder };
}

function SingleValue() {
  const { formatMessage } = useIntl();

  const { props, option, placeholder } = useSingleValueData();
  const placeholderText = isMessageDescriptor(placeholder) ? formatMessage(placeholder) : placeholder;

  return <div {...props}>{option ? <>{option.label}</> : <span className="text-gray-50">{placeholderText}</span>}</div>;
}

function useMultiValueData() {
  const store = useStore();
  const selection = useSelector(store, (s) => s.selection);
  const options = useSelector(store, (s) => s.options);
  const placeholder = useSelector(store, (s) => s.placeholder);

  const selectedOptions = leafOptions(options).filter((option) => selection.includes(option.value));

  const props = {
    className: "flex flex-wrap items-center gap-2 min-h-8",
  } as const satisfies React.HTMLAttributes<HTMLDivElement> & DataAttrs;

  return { placeholder, selectedOptions, props };
}

function MultiValue() {
  const { selectedOptions, props, placeholder } = useMultiValueData();
  const { formatMessage } = useIntl();

  const placeholderText = isMessageDescriptor(placeholder) ? formatMessage(placeholder) : placeholder;

  return (
    <div {...props}>
      {selectedOptions.length ? (
        selectedOptions.map((option) => <ValueChip key={option.value} option={option} />)
      ) : (
        <span className="text-gray-50">{placeholderText}</span>
      )}
    </div>
  );
}

function ValueChip({ option }: { option: LeafOption }) {
  const { formatMessage } = useIntl();
  const store = useStore();
  const selection = useSelector(store, (s) => s.selection);
  const clearable = useSelector(store, (s) => s.clearable);
  const canClear = clearable || selection.length > 1;

  const onClear = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (canClear) store.toggleOption(option.value, "indirect");
  };

  return (
    <Chip
      asButton={false}
      removeable={canClear}
      title={canClear ? formatMessage(t.remove) : undefined}
      onClick={onClear}
      disabled={selection.length === 1}
      onKeyDown={(event) => {
        if (event.code === "Enter" || event.code === "Space") {
          onClear(event);
        }
      }}
    >
      {option.label}
    </Chip>
  );
}

const StoreContext = React.createContext<SelectStore | undefined>(undefined);

function useStore() {
  const store = React.useContext(StoreContext);
  invariant(store, "useStore must be used within a StoreContext.Provider");
  return store;
}

function useSelector<T>(store: SelectStore, selector: (state: SelectStore["state"]) => T) {
  return useSyncExternalStoreWithSelector(
    store.subscribe,
    () => store.state,
    () => store.state,
    selector,
  );
}

const defaultComponents: Components = {
  SingleValue: SingleValue,
  MultiValue: MultiValue,
  Option: Option,
  GroupOption: GroupOption,
  LeafOption: LeafOption,
};

function useResolveComponent<Name extends keyof Components>(name: Name): Components[Name] {
  const store = useStore();
  const customComponent = useSelector(store, (s) => s.components?.[name]);
  return customComponent || defaultComponents[name];
}

function valueToSelection(value: string | string[] | null) {
  return Array.isArray(value) ? value : value !== null ? [value] : [];
}

function syncNativeSelect(select: HTMLSelectElement, selection: string[]) {
  select.innerHTML = "";
  selection.forEach((value) => {
    const option = document.createElement("option");
    option.setAttribute("value", value);
    option.setAttribute("selected", "");
    select.appendChild(option);
  });
}

function leafOptions(options: readonly AnyOption[]) {
  return options.flatMap((option) => ("options" in option ? option.options : option));
}

function scrollToOption(uid: string, value: string) {
  const optionId = `${uid}-${value}`;
  document.querySelector(`[data-optionid="${optionId}"]`)?.scrollIntoView({ block: "nearest" });
}

function focusSearch(uid: string) {
  document.querySelector<HTMLInputElement>(`[data-searchid="${uid}"]`)?.focus();
}

function matchOption(option: LeafOption, search: string) {
  return matchText(option.label, search);
}

function matchingOptions(options: readonly AnyOption[], search: string | null) {
  if (!search) return options;

  return options
    .map((option) => {
      if ("options" in option) {
        const subOptions = option.options.filter((option) => matchOption(option, search));
        if (!subOptions.length) return null;
        return { ...option, options: subOptions };
      } else {
        return matchOption(option, search) ? option : null;
      }
    })
    .filter(Boolean) as AnyOption[];
}

type SelectState = {
  uid: string;
  selection: string[];
  isMenuOpen: boolean;
  multiple: boolean;
  clearable: boolean;
  options: readonly AnyOption[];
  components: Partial<Components> | undefined;
  canSearch: boolean;
  search: string | null;
  highlightedValue: string | null;
  highlightVisible: boolean;
  placeholder: string | MessageDescriptor;
};

class SelectStore {
  store: Store<SelectState>;
  onChange: (selection: string[]) => void = () => {};

  constructor(
    init: Pick<SelectState, "uid" | "selection" | "options" | "multiple" | "clearable" | "components" | "placeholder">,
  ) {
    this.store = new Store<SelectState>({
      ...init,
      isMenuOpen: false,
      canSearch: false,
      search: null,
      highlightedValue: null,
      highlightVisible: false,
    });
  }

  get state() {
    return this.store.state;
  }
  get setState() {
    return this.store.setState;
  }
  get batch() {
    return this.store.batch;
  }
  get subscribe() {
    return this.store.subscribe;
  }

  setSearch(search: string | null) {
    this.setState((state) => ({ ...state, search }));
  }

  setIsMenuOpen(isMenuOpen: boolean) {
    this.batch(() => {
      this.setState((state) => ({ ...state, isMenuOpen }));
      if (!isMenuOpen) {
        this.setState((state) => ({
          ...state,
          search: null,
          highlightedValue: null,
          highlightVisible: false,
        }));
      }
    });
  }

  setHighlightedOption(value: string, source: "keyboard" | "mouse") {
    this.setState((state) => ({
      ...state,
      highlightedValue: value,
      highlightVisible: source === "keyboard",
    }));
  }

  toggleOption(value: string, origin: "keyboard" | "mouse" | "indirect") {
    const { uid, multiple, clearable, options } = this.state;
    const selection = new Set(this.state.selection);

    if (multiple) {
      if (origin !== "indirect") {
        this.setHighlightedOption(value, origin);
        scrollToOption(uid, value);
        focusSearch(uid);
      }

      if (!selection.has(value)) {
        selection.add(value);
      } else if (clearable || selection.size > 1) {
        selection.delete(value);
      }

      this.setSelection(
        leafOptions(options)
          .filter((option) => selection.has(option.value))
          .map((option) => option.value),
      );
    } else {
      if (!selection.has(value)) {
        selection.clear();
        selection.add(value);
      } else if (clearable) {
        selection.clear();
      }

      this.setIsMenuOpen(false);
      this.setSelection([...selection]);
    }
  }

  setSelection(selection: string[]) {
    this.setState((state) => ({ ...state, selection }));
    this.onChange(selection);
  }
}

const t = defineMessages({
  search: { id: "select_input_search", defaultMessage: "Search" },
  remove: { id: "select_input_remove", defaultMessage: "Remove" },
  clear: { id: "select_input_clear", defaultMessage: "Clear" },
  noOptions: { id: "select_input_no_options", defaultMessage: "No options" },
});

export {
  SelectInput,
  Button as SelectInputButton,
  GroupOption as SelectInputGroupOption,
  LeafOption as SelectInputLeafOption,
  Menu as SelectInputMenu,
  MultiValue as SelectInputMultiValue,
  Option as SelectInputOption,
  Options as SelectInputOptions,
  Actions as SelectInputActions,
  Search as SelectInputSearch,
  SingleValue as SelectInputSingleValue,
  Trigger as SelectInputTrigger,
};

export {
  useGroupOptionData as useSelectInputGroupOptionData,
  useLeafOptionData as useSelectInputLeafOptionData,
  useMultiValueData as useSelectInputMultiValueData,
  useOptionsData as useSelectInputOptionsData,
  useSingleValueData as useSelectInputSingleValueData,
  useStore as useSelectInputStore,
  useSelector as useSelectInputStoreSelector,
};

export { type Components as SelectInputComponents, type Props as SelectInputProps };
