import { cn, cva, type VariantProps } from "@nephroflow/design-system/styling/utils";
import * as React from "react";

import { defineMessages, useIntl } from "~/intl";

import { CloseIcon } from "./icon";
import { focusOutlineClassName } from "./inputs/constants";

const shipVariants = cva(
  [
    "inline-flex max-w-48 cursor-default items-center gap-2 rounded-full bg-gray-20 px-4 transition-colors",
    "data-[disabled]:bg-gray-10",
    "select-none data-[disabled]:bg-gray-10",
    focusOutlineClassName,
  ],
  {
    variants: {
      meaning: {
        default: ["data-[enabled]:bg-gray-20", "data-[enabled]:hover:bg-gray-30 data-[enabled]:active:bg-gray-40"],
        critical: [
          "text-white data-[enabled]:bg-red",
          "data-[enabled]:hover:bg-red-110 data-[enabled]:active:bg-red-120",
        ],
        success: ["data-[enabled]:bg-green", "data-[enabled]:hover:bg-green-110 data-[enabled]:active:bg-green-120"],
        warning: ["data-[enabled]:bg-yellow", "data-[enabled]:hover:bg-yellow-110 data-[enabled]:active:bg-yellow-120"],
      },
      size: {
        compact: "",
        default: "py-1",
        large: "py-4",
      },
    },

    defaultVariants: {
      meaning: "default",
      size: "default",
    },
  },
);

type BasePorps = { removeable?: boolean } & VariantProps<typeof shipVariants>;

type ChipProps<AsButton extends boolean> = AsButton extends true
  ? { asButton?: AsButton } & BasePorps & React.ButtonHTMLAttributes<HTMLButtonElement>
  : { asButton: AsButton; disabled?: boolean } & BasePorps & React.HTMLAttributes<HTMLDivElement>;

function Chip<AsButton extends boolean = true>({
  asButton = true,
  className,
  children,
  removeable = false,
  meaning,
  size,
  disabled,
  ...props
}: ChipProps<AsButton>) {
  const { formatMessage } = useIntl();

  const [hasOverflow, setHasOverflow] = React.useState(false);

  const rootRef = React.useRef<HTMLElement>(null);
  const contentRef = React.useRef<HTMLElement>(null);

  const detectOverflow = React.useCallback(() => {
    const root = rootRef.current;
    const content = contentRef.current;
    if (!root || !content) return;

    const textRange = new Range();
    textRange.selectNodeContents(content);
    const contentWidth = content.getBoundingClientRect().width;
    const textWidth = textRange.getBoundingClientRect().width;

    setHasOverflow(textWidth > contentWidth);
  }, []);

  React.useEffect(() => {
    detectOverflow();

    const root = rootRef.current;
    if (root) {
      const observer = new ResizeObserver(() => detectOverflow());
      observer.observe(root);
      return () => observer.disconnect();
    }
  }, [detectOverflow]);

  const Comp = asButton ? "button" : "div";

  // TODO: Replace title prop usage with a Tooltip component
  const title = hasOverflow ? contentRef.current?.textContent || "" : undefined;

  return (
    <Comp
      ref={rootRef}
      type={asButton ? "button" : (undefined as never)}
      disabled={asButton ? disabled : (undefined as never)}
      className={cn(shipVariants({ meaning, size }), className)}
      data-enabled={disabled ? undefined : ""}
      data-disabled={disabled ? "" : undefined}
      {...(props as any)}
    >
      <span ref={contentRef} title={title} className="truncate">
        {children}
      </span>
      {removeable ? (
        <div className="-m-1 flex h-6 w-6 items-center justify-center" aria-label={formatMessage(t.remove)}>
          <CloseIcon className="h-4 w-4" />
        </div>
      ) : null}
    </Comp>
  );
}

const t = defineMessages({
  remove: {
    id: "chip_remove",
    defaultMessage: "Remove",
  },
});

export { Chip };
