import { HttpResponse } from "~/services/http";

import { FormSubmitContext } from "~/shared/components/form";

export function errorHandler(error: unknown, context: FormSubmitContext) {
  if (error instanceof HttpResponse) {
    const { errors } = error;

    const globalErrors = errors.filter((e) => !e.source).map((e) => e.detail);

    if (globalErrors.length) {
      context.setGlobalErrors(globalErrors);
    }

    const fieldErrors = Object.fromEntries(errors.filter((e) => e.source !== null).map((e) => [e.source, e.detail]));

    if (Object.keys(fieldErrors).length) {
      context.setFieldErrors(fieldErrors);
    }
  } else if (error instanceof Error) {
    context.setGlobalErrors([error.message]);
  } else {
    const genericGlobalError = "Unknown error. Try again.";
    context.setGlobalErrors([genericGlobalError]);
  }
}
