import { cn } from "@nephroflow/design-system/styling/utils";
import * as Popover from "@radix-ui/react-popover";
import * as React from "react";

const DropdownMenu = React.forwardRef<
  React.ElementRef<typeof Popover.Content>,
  React.ComponentProps<typeof Popover.Content>
>(({ className, children, ...props }, ref) => {
  return (
    <Popover.Content
      ref={ref}
      align="start"
      side="bottom"
      sideOffset={2}
      updatePositionStrategy="always"
      className={cn(
        "relative z-[100] flex max-h-[calc(var(--radix-popover-content-available-height)-8px)] min-w-48 max-w-80 flex-col overflow-hidden rounded border border-gray-10 bg-white shadow-lg",
        "focus:outline-none",
        className,
      )}
      {...props}
    >
      {children}
    </Popover.Content>
  );
});
DropdownMenu.displayName = "DropdownMenu";

export { DropdownMenu };
